import { Link } from "gatsby";
import React from "react";
import { useEffect, useState } from "react";



const SmallLogo = ({title}) => {

  const [ playAnimation, setPlayAnimation ] = useState(false);


  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
      const wow = setTimeout(() => showElement(), 4400);
    return () => clearTimeout(wow);
  };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  // useEffect(() => {
  //   const wow = setTimeout(() => showElement(), 4400);
  //   return () => clearTimeout(wow);
  // }, []);

  const [seen, setSeen] = useState(false);

  const showElement = () => {
    setSeen((seen)=>!seen);
  };

    return ( 
        <>
       { seen && (
       <div className={playAnimation ? "logo" : 'hidden'}>
       <Link to="/">{title}</Link>
     </div>
       )}
        </>
     );
}
 
export default SmallLogo;