import React from "react";
import Footer from "../navigation/footer";
import Navigation from "../navigation/nav";
import ResNav from "../navigation/resNav";
import { useInView } from "react-intersection-observer";
import useReadingProgress from "../../lib/hooks";
import SmallLogo from "../navigation/smallLogo";

const Layout = ({ children, siteTitle }) => {
  const completion = useReadingProgress();

  const { ref: myRef, inView: myElementIsVisible } = useInView();

  return (
    <>
      <div className="fullBg">
        <div className="megaWrap">
          
          {/* Logo */}
          <header>
            <span
              style={{ transform: `translateX(${completion - 100}%)` }}
              className="progressBar"
            />
            <div className={myElementIsVisible ? "hidden" : "logoWrap"}>
              <SmallLogo title={siteTitle} />
            </div>
          </header>

          {/* Navs */}
          <Navigation />
          <ResNav />


          {/* Content */}
          <div 
          className="superWrap"
          >{children}</div>

          {/* Footer */}
          <span ref={myRef}>
            <Footer siteTitle={siteTitle} />
          </span>

        </div>
      </div>
    </>
  );
};

export default Layout;
