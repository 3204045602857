import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import LayoutWork from "../components/layouts/layoutWork";

export const query = graphql`
  query {
    site: sanitySiteSettings {
      title
      keywords
      description
    }
    allSanityNews {
      press: nodes {
        id
        name
        _id
        bits: newsContainer {
          year
          title
          _id
          pressLink {
            _type
            href
          }
          logoPub {
            alt
            asset {
              _id
              id
              height
              size
              url
              width
              gatsbyImageData
            }
          }
          screenshot {
            alt
            asset {
              _id
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              url
              uploadId
              id
            }
          }
          order
        }
      }
    }
  }
`;

const News = (pProps) => {
  const { data, errors } = pProps;

  const press = data.allSanityNews.press[0];
  const pits = data.allSanityNews.press[0].bits;

  // console.log(press);
  // console.log(pits);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <LayoutWork>
      <Seo title="News" />
      {/* <pre>{JSON.stringify(pits, null,2)}</pre> */}
      <div>
        <div className="pressGallWrap">
          {pits.map((i) => (
            <div key={i._id} className="pressCard group">
              <a href={i.pressLink.href} target="_blank" rel="noreferrer">
                <div className="presslogowrap">
                  <GatsbyImage
                    className="pressLogo"
                    objectFit="contain"
                    key={i.logoPub.asset.id}
                    image={i.logoPub.asset.gatsbyImageData}
                    alt={i.screenshot.alt}
                  />
                </div>
                <h2 className="pressTitle">{i.year}</h2>
                <span className="group">
                  <GatsbyImage
                    className="pressImg"
                    objectFit="contain"
                    key={i.screenshot.asset.id}
                    image={i.screenshot.asset.gatsbyImageData}
                    alt={i.screenshot.alt}
                  />
                </span>
              </a>
            </div>
          ))}
        </div>
        <h1 className="pageTitle">{press.name}</h1>
        <div className="controlWrapper">
          <hr className="line" />
        </div>
      </div>
    </LayoutWork>
  );
};

export default News;
